import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useUserService } from '../services/users/userService'
import { useAuth } from './auth'
import type { UserModel, EditUserForm } from '../services/users/userService'
import { useToast } from 'vuestic-ui'

export const useUserStore = defineStore('user', () => {
  // Estado
  const user = ref<UserModel | null>(null)
  const loading = ref<boolean>(false)
  const error = ref<string | null>(null)

  const { GetDetails, Update, ChangePassword } = useUserService()
  const authStore = useAuth()
  const { init: notify } = useToast()
  const is2FAEnabled = ref(false)
  const USER_LOCALSTORAGE = 'user'

  // Computed properties
  const isAuthenticated = computed(() => !!authStore.Token)
  const userName = computed(() => user.value?.username ?? '')
  const fullName = computed(() => (user.value ? `${user.value.firstName} ${user.value.lastName}` : ''))
  const userEmail = computed(() => user.value?.email ?? '')
  const profileImage = computed(() => user.value?.profileImage ?? '')
  const memberSince = computed(() => user.value?.createdAt ?? '')

  // Actions
  const fetchUserDetails = async () => {
    let sub = authStore.sub
    if (!sub) {
      sub = localStorage.getItem(USER_LOCALSTORAGE)
      if (!sub) return
    }
    loading.value = true
    error.value = null
    try {
      const { data, error: fetchError } = await GetDetails(sub)
      if (fetchError) {
        error.value = fetchError.message
        user.value = null
        notify({
          title: 'Error',
          message: error?.value ?? 'unknown error',
        })
      } else {
        user.value = data
      }
    } catch (err: any) {
      error.value = err.message
      notify({
        title: 'Error',
        message: error?.value ?? 'unknown error',
      })
    } finally {
      loading.value = false
    }
  }

  const updateUserProfile = async (updatedData: Partial<EditUserForm>) => {
    if (!authStore.sub) return false
    loading.value = true
    error.value = null
    try {
      const { error: updateError } = await Update(authStore.sub, updatedData)
      if (updateError) {
        error.value = updateError.message
        notify({
          title: 'Error',
          message: error?.value ?? 'unknown error',
        })
        return false
      }
      // Actualizar los datos del usuario en el store
      user.value = { ...user.value, ...updatedData } as UserModel
      notify({ title: 'Éxito', message: 'Elemento actualizado.', color: 'success' })
      return true
    } catch (err: any) {
      error.value = err.message
      notify({
        title: 'Error',
        message: error?.value ?? 'unknown error',
      })
      return false
    } finally {
      loading.value = false
    }
  }

  const updatePassword = async (oldPassword: string, newPassword: string) => {
    const authStore = useAuth()
    if (!authStore.sub) return false

    loading.value = true
    error.value = null

    try {
      const { error: updateError } = await ChangePassword(userEmail.value, oldPassword, newPassword)

      if (updateError) {
        error.value = updateError.message
        notify({
          title: 'Error',
          message: error.value ?? 'unknown error',
        })
        return false
      }
      // notify({
      //   title: 'Success',
      //   message: 'Password updated successfully',
      //   color: 'success',
      // })
      return true
    } catch (err: any) {
      error.value = err.message
      notify({
        title: 'Error',
        message: error.value ?? 'unknown error',
      })
      return false
    } finally {
      loading.value = false
    }
  }

  const clearUserData = () => {
    user.value = null
    error.value = null
  }

  const toggle2FA = () => {
    is2FAEnabled.value = !is2FAEnabled.value
  }

  // Fetch inicial si el usuario ya está autenticado
  if (isAuthenticated.value) {
    fetchUserDetails()
  }

  return {
    user,
    loading,
    error,
    userName,
    fullName,
    userEmail,
    profileImage,
    memberSince,
    is2FAEnabled,
    fetchUserDetails,
    updateUserProfile,
    updatePassword,
    clearUserData,
    toggle2FA,
  }
})

// import { defineStore } from 'pinia'

// export const useUserStore = defineStore('user', {
//   state: () => {
//     return {
//       userName: 'Vasili Savitski',
//       email: 'vasili@gmail.com',
//       memberSince: '8/12/2020',
//       pfp: 'https://picsum.photos/id/22/200/300',
//       is2FAEnabled: false,
//     }
//   },

//   actions: {
//     toggle2FA() {
//       this.is2FAEnabled = !this.is2FAEnabled
//     },

//     changeUserName(userName: string) {
//       this.userName = userName
//     },
//   },
// })
