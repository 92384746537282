// import { Claims } from '@/utils/enumsHandler'

export default [
  {
    name: 'app-versions',
    path: '/app-versions',
    component: () => import('@/pages/app_version/index.vue'),
    meta: {
      title: 'Versiones de la app',
    },
  },

  {
    meta: {
      title: 'Editar Verión',
      // requiredClaims: [Claims.WriteVers/],
    },
    name: 'app-version-detail',
    path: '/app-versions/:id/edit',
    component: () => import('@/components/AppVersion/CreateOrModify.vue'),
    props: (route: any) => ({
      elementId: route.params.id,
      isNew: false,
    }),
  },
  {
    meta: {
      title: 'Crear Versión',
      // requiredClaims: [Claims.WriteGeneralPromotion],
    },
    name: 'app-version-creation',
    path: 'app-versions/creation',
    component: () => import('@/components/AppVersion/CreateOrModify.vue'),
    props: {
      isNew: true,
    },
  },
]
