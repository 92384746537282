<template>
  <VaDropdown
    :close-on-anchor-click="true"
    :close-on-content-click="false"
    placement="left"
    :offset="[5, 0]"
    class="overflow-hidden m-0 p-0"
  >
    <template #anchor>
      <div
        class="icon-wrapper text-gray-600 active:bg-light-primary-container p-4 hover:bg-light-surface-container-high hover:text-primary transition-colors relative flex justify-center items-center active:scale-95 mr-2.5 rounded-r-full group relative"
      >
        <!-- Ícono principal del teléfono -->
        <VaIcon
          name="mso-phone"
          class="mt-2 mr-4 group-hover:translate-x-2 group-active:translate-x-0 transition-transform hover:bg-gray-200 cursor-pointer"
          size="large"
        />

        <!-- Ícono del historial de llamadas en la esquina superior derecha -->
        <RouterLink
          v-if="hasPermission(Claims.ReadCallRecord)"
          class="absolute top-3 right-6"
          :to="{ name: 'calls' }"
          @click.stop
        >
          <VaIcon
            name="history"
            class="absolut hover:scale-125 top-0 right-0 text-primary rounded-full shadow-md transition-all cursor-pointer"
            size="small"
          />
        </RouterLink>
        <!-- <VaButton preset="primary" size="medium" icon-right="mso-phone" class="my-1" border-color="primary" /> -->
      </div>
    </template>
    <VaDropdownContent class="p-0 bg-white rounded-lg shadow-lg w-64">
      <VaCard>
        <!-- Tabs de Navegación -->
        <VaTabs v-model="activeTab">
          <VaTab :value="CallTabs.Keypad">Teclado</VaTab>
          <VaTab :value="CallTabs.Contacts">Contactos</VaTab>
          <VaTab :value="CallTabs.History">Historial</VaTab>
        </VaTabs>

        <!-- Contenido de las pestañas -->
        <VaCardContent class="min-h-[300px]">
          <div v-if="activeTab === CallTabs.Keypad">
            <KeypadComponent @call="startCall" />
          </div>
          <div v-else-if="activeTab === CallTabs.Contacts">
            <ContactsComponent />
          </div>
          <div v-else-if="activeTab === CallTabs.History">
            <CallHistoryComponent />
          </div>
          <!-- <div v-else-if="activeTab === CallTabs.Calling"> -->
          <!-- Mostrar el CallScreen si hay una llamada en curso -->
          <!-- <CallScreen v-if="isCalling && currentCall" :phoneNumber="currentCall" @end-call="endCall" /> -->
          <!-- </div> -->
        </VaCardContent>
      </VaCard>
    </VaDropdownContent>
  </VaDropdown>
  <VaModal
    v-model="showModal"
    size="auto"
    blur
    hide-default-actions
    no-dismiss
    class="flex items-center justify-center shadow-lg m-0 p-0 custom-modal"
    style="--va-modal-dialog-border-radius: 12px"
  >
    <VaCard class="flex flex-col items-center m-0 p-0">
      <!-- Título de la llamada -->
      <VaCardTitle class="text-lg font-semibold flex flex-col w-full gap-0 border-gray-300">
        <div class="flex flex-col items-center gap-1 text-primary-600 m-0 p-0">
          <!-- <VaIcon name="phone_in_talk" color="gray" size="20px" /> -->
          <VaIcon name="mso-person" size="100px" class="mt-0 pt-0 mb-2" />
          <span v-if="isCalling" class="whitespace-nowrap opacity-80">Llamando</span>
        </div>
        <span v-if="!isCalling" class="whitespace-nowrap opacity-80 mb-1">{{ callerName }}</span>
        <div v-if="!isCalling">
          <span v-if="extractDiversionNumber((session as any)._request.data)" class="whitespace-nowrap opacity-80 mb-1"
            >Movil</span
          >
          <span v-else class="whitespace-nowrap opacity-80 mb-1">Ext</span>
        </div>
        <p v-if="!isCalling" class="whitespace-nowrap opacity-80"># {{ callerNumber }}</p>
        <span v-if="isCalling" class="whitespace-nowrap opacity-80"># {{ callNumber }}</span>
      </VaCardTitle>

      <VaCardContent class="m-0 p-0">
        <VaRow justify="center" align="center" class="gap-20 flex-nowrap flex p-0">
          <!-- Botón de Aceptar -->
          <VaCol
            v-if="sessionStatus !== (SessionStatus.Connected as number) && !isCalling"
            cols="auto"
            class="flex flex-col items-center"
          >
            <!-- <VaCard
              class="w-24 h-24 flex items-center justify-center rounded-xl bg-gray-200 hover:bg-gray-200 cursor-pointer border-2 border-gray-200 shadow-lg"
              @click="answer(session)">
              <VaIcon name="phone" size="65px" color="primary" />
            </VaCard> -->
            <VaButton color="success" round class="m-2 p-2" size="small" @click="answer(session)">
              <VaIcon name="phone" size="20px" />
            </VaButton>
            <!-- <VaIcon name="phone" @click="answer(session)" /> -->
            <span v-if="!isCalling" class="whitespace-nowrap opacity-80">Aceptar</span>
          </VaCol>
          <!-- Botón de Pausar -->
          <VaCol
            v-if="sessionStatus === (SessionStatus.Connected as number) || isCalling"
            cols="auto"
            class="flex flex-col items-center"
          >
            <!-- <VaCard
              class="w-24 h-24 flex items-center justify-center rounded-xl bg-gray-200 hover:bg-gray-200 cursor-pointer border-2 border-gray-200 shadow-lg"
              @click="hold(session)">
              <VaIcon :name="isOnHold ? 'play_arrow' : 'pause'" size="65px" :color="isOnHold ? 'primary' : 'primary'" />
            </VaCard> -->
            <VaButton color="primary" round class="m-2 p-2" size="small" @click="hold(session)">
              <VaIcon :name="isOnHold ? 'play_arrow' : 'pause'" size="20px" />
            </VaButton>
            <!-- <VaIcon :name="isOnHold ? 'play_arrow' : 'pause'" size="65px" :color="isOnHold ? 'primary' : 'primary'"
              @click="hold(session)" /> -->
            <span v-if="!isOnHold && !isCalling" class="whitespace-nowrap opacity-80">Pausar</span>
            <span v-if="isOnHold && !isCalling" class="whitespace-nowrap opacity-80">Reanudar</span>
          </VaCol>
          <!-- Botón de Rechazar -->
          <VaCol cols="auto" class="flex flex-col items-center">
            <!-- <VaCard
              class="w-24 h-24 flex items-center justify-center rounded-xl bg-gray-200 hover:bg-gray-200 cursor-pointer border-2 border-gray-200 shadow-lg"
              @click="close(session)">
              <VaIcon name="call_end" size="65px" color="secondary" />
            </VaCard> -->
            <VaButton color="danger" round class="m-2 p-2" size="small" @click="close(session)">
              <VaIcon name="call_end" size="20px" />
            </VaButton>
            <!-- <VaIcon name="call_end" @click="close(session)" /> -->
            <span v-if="!isCalling" class="whitespace-nowrap opacity-80">Rechazar</span>
          </VaCol>
        </VaRow>
      </VaCardContent>
    </VaCard>
  </VaModal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useSessionStore } from '../../stores/callSessionStore'
import { useSipStore } from '../../stores/useSipStore'

import KeypadComponent from './Keypad.vue'

import type { RTCSession } from 'jssip/lib/RTCSession'
// import CallScreen from './CallScreen.vue'
import { Claims } from '../../utils/enumsHandler'
import { eventBus } from '../../utils/eventBus'

import { usePermissions } from '../../utils/usePermisions'

const { hasPermission } = usePermissions()

enum SessionStatus {
  'Idle' = 0, // Estado incierto o inactivo
  'Calling' = 1, // Marcando
  'Ringing' = 2, // Sonando
  'Request_Received' = 3, // Petición recibida
  'Incoming_Call' = 4, // Llamada entrante
  'Answered' = 5, // Llamada contestada
  STATUS_WAITING_FOR_ACK = 6, // Esperando ACK (mantengo el nombre original)
  'Cancelled' = 7, // Llamada cancelada
  'Terminated' = 8, // Llamada finalizada
  'Connected' = 9, // Llamada conectada
}

enum CallTabs {
  None = -1,
  Keypad = 0,
  Contacts = 1,
  History = 2,
  Calling = 3,
}

const sessionStore = useSessionStore()
const sipStore = useSipStore()

const activeTab = ref<CallTabs>(CallTabs.Keypad)
const previousTab = ref<CallTabs>(CallTabs.None)
// const isOpen = ref(false)

const isCalling = ref(false)
const currentCall = ref<string | null>(null)

const liveSessionArray = computed(() => sessionStore.getLiveSessionArray)
const session = computed(() => sessionStore.getLiveSessionArray[0])
// Computed que extrae solo el status de la primera sesión
const sessionStatus = computed(() => liveSessionArray.value[0]?.status)
// const isOnHold = computed(() => session.value?.isOnHold()?.local ?? false)
const isOnHold = ref(false)
const callerName = computed(() => session.value.remote_identity.display_name)
const callerNumber = computed(() => session.value.remote_identity.uri.user)
const callNumber = ref('')
const showModal = ref(false)

const extractDiversionNumber = (data: string): string => {
  const diversionMatch = data ? data.match(/Diversion:\s*<sip:(\+\d+)@/) : ''
  return diversionMatch ? diversionMatch[1] : ''
}

const startCall = (phoneNumber: string) => {
  // showModal.value = false
  callNumber.value = phoneNumber
  outgoingCall(phoneNumber)
  currentCall.value = phoneNumber
  isCalling.value = true
  previousTab.value = activeTab.value
  activeTab.value = CallTabs.Calling
}

const endCall = () => {
  showModal.value = false
  isCalling.value ? (activeTab.value = previousTab.value) : (activeTab.value = CallTabs.Keypad)
  callNumber.value = ''
  isCalling.value = false
  currentCall.value = null
  previousTab.value = CallTabs.None
  if (eventBus.numberToCall) eventBus.numberToCall = ''
}

watch(sessionStatus, (newStatus, oldStatus) => {
  if (newStatus !== oldStatus) {
    if (newStatus !== (SessionStatus.Terminated as number)) {
      showModal.value = true
    } else {
      endCall()
    }
  }
})

const outgoingCall = (phoneNumber: string) => {
  const liveSessionArray = sessionStore.getLiveSessionArray
  liveSessionArray.map((session) => {
    session.hold()
  })

  sipStore.userAgent?.call(phoneNumber, {
    // eventHandlers: meventHandlers,
    mediaConstraints: {
      audio: true,
      video: false,
    },
  })
}

// const reCall = (phoneNumber: string) => {
//   // ua1.call(phoneNumber, {
//   //   mediaConstraints: {
//   //     audio: true,
//   //     video: false
//   //   }
//   // });
// }

const answer = (session: RTCSession) => {
  session.answer()
}

const close = (session: RTCSession) => {
  if (session.status == 4) {
    session.terminate({
      status_code: 486,
      reason_phrase: 'Busy Here',
    })
  } else {
    session.terminate()
  }
  endCall()
}

const hold = (session: RTCSession) => {
  if (session.isOnHold().local) {
    session.unhold()
    session.unmute()
    isOnHold.value = false
  } else {
    session.hold()
    session.mute()
    isOnHold.value = true
  }
}

watch(
  () => eventBus.numberToCall,
  (newVal) => {
    if (newVal) {
      startCall(newVal)
    }
  },
)
</script>

<style lang="scss" scoped></style>
