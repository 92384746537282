import type { IGetElements, IDetails, IUpdateable, ICreate, IRemove, DynamicFilter } from '../commonInterfaces'
import { useFetcher } from '../../utils/fetch'
import { RoleModel } from '../access/rolesService'
import { UUID } from 'crypto'

export interface EditUserPermissionsForm {
  claims: string[]
  roles: string[]
}

export interface EditUserForm {
  userId: string
  username: string
  firstName: string
  lastName: string
  profileImage: string
  address: string
  birthday: string
  sex: number
}

export interface CreateUserForm {
  email: string
  password: string
  username: string
  firstName: string
  lastName: string
  phone: string
  profileImage: string
  address: string
  birthday: string
  sex: number
}

export interface UserModel {
  _id: UUID
  createdAt: string
  updatedAt: string
  email: string
  firstName: string
  lastName: string
  username: string
  profileImage: string | null
  verified: boolean
  emailVerificationCode: string
  stripeCustomerId: string
  balance: number
  deletedAt: string | null
  claims: string[]
  birthday: string | null
  address: string | null
  sex: string | null
  phone: string | null
  expandedRoles: RoleModel[]
  roles: string[]
}

interface IUserService
  extends IGetElements<UserModel>,
    IDetails<UserModel>,
    IUpdateable<EditUserForm>,
    ICreate<CreateUserForm, UserModel>,
    IRemove {
  ChangeUserPermission(userId: string, permisions: EditUserPermissionsForm): Promise<{ error: Error | null }>
  ChangePassword(
    userEmail: string,
    oldPassword: string,
    newPassword: string,
  ): Promise<{ data: any; error: Error | null }>
}

export const useUserService = () => {
  const GetAll = async (page: number, pageSize: number, filter: DynamicFilter) => {
    return await useFetcher().Fetch(`identity/user?page=${page ?? 1}&limit=${pageSize ?? 10}`, {
      method: 'POST',
      body: {
        filter,
      },
    })
  }

  const GetDetails = async (id: string) => {
    const { data, error } = (await GetAll(1, 1, { _id: { is: id }, address: {} })) as any

    return { data: data.items[0], error }
  }

  const Create = async (data: CreateUserForm) => {
    return await useFetcher().Fetch(`identity/user/signup`, {
      method: 'POST',
      body: {
        ...data,
      },
    })
  }

  const Update = async (id: string, data: Partial<EditUserForm>) => {
    return await useFetcher().Fetch(`identity/user/${id}`, {
      method: 'PUT',
      body: {
        userId: id,
        ...data,
      },
    })
  }

  const Remove = async (id: string) => {
    return await useFetcher().Fetch(`identity/user/${id}`, {
      method: 'DELETE',
    })
  }

  const ChangeUserPermission = async (id: string, permisions: EditUserPermissionsForm) => {
    return await useFetcher().Fetch(`identity/user/${id}`, {
      method: 'PUT',
      body: {
        userId: id,
        claims: permisions.claims,
        roles: permisions.roles,
      },
    })
  }

  // Nuevo método para cambiar la contraseña
  const ChangePassword = async (userEmail: string, oldPassword: string, newPassword: string) => {
    return await useFetcher().Fetch('identity/user/me/update_password', {
      method: 'POST',
      body: {
        userEmail,
        oldPassword,
        newPassword,
      },
    })
  }

  return {
    Create,
    GetDetails,
    GetAll,
    Update,
    Remove,
    ChangeUserPermission,
    ChangePassword,
  } as IUserService
}
