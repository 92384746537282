import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'crm',
    path: 'crm',
    meta: {
      title: 'Interacciones',
      // requiredClaims: [Claims.ReadClients],
    },
    redirect: { name: 'interactions' },
    component: () => import('@/pages/crm/CrmLayout.vue'),
    children: [
      {
        name: 'interactions',
        path: 'interactions',
        component: () => import('../../pages/crm/interactions/InteractionsPage.vue'),
      },
      {
        name: 'interaction-details',
        path: 'interactions/:id',
        component: () => import('../../pages/crm/interactions/components/interactionDetails/InteractionDetails.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          title: 'Detalles de la interacción',
        },
      },
      {
        name: 'interaction-create',
        path: 'interactions/create',
        component: () => import('@/pages/crm/interactions/components/InteractionForm.vue'),
        props: (route) => ({
          stageId: route.query.stage,
          userId: route.query.userId,
          subject: route.query.subject,
          type: route.query.type,
          channel: route.query.channel,
          description: route.query.description,
          agentId: route.query.agentId,
        }),
        meta: {
          title: 'Nueva interacción',
        },
      },
      {
        name: 'ticket-details',
        path: 'ticket/:id',
        component: () => import('@/pages/crm/tickets/components/TicketDetails.vue'),
        props: (route) => ({
          id: route.params.id,
          redirect: route.query.redirect,
        }),
        meta: {
          title: 'Detalles del ticket',
        },
      },
      {
        name: 'agents',
        path: 'agents',
        meta: { title: 'Agentes' },
        component: () => import('@/pages/crm/agents/AgentsPage.vue'),
      },
      {
        name: 'agent-details',
        path: 'agents/:id',
        component: () => import('@/pages/crm/agents/components/AgentForm.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          title: 'Detalles del agente',
        },
      },
      {
        name: 'agent-create',
        path: 'agents/create',
        component: () => import('@/pages/crm/agents/components/AgentForm.vue'),
        props: (route) => ({
          userId: route.query.userId,
        }),
        meta: {
          title: 'Registrar agente',
        },
      },
      {
        name: 'stage-details',
        path: 'stages/:id',
        component: () => import('@/pages/crm/stages/components/StageForm.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          title: 'Detalles de la etapa',
        },
      },
      {
        name: 'stage-create',
        path: 'stages/create',
        component: () => import('@/pages/crm/stages/components/StageForm.vue'),
        props: (route) => ({
          userId: route.query.userId,
        }),
        meta: {
          title: 'Registrar etapa',
        },
      },
      {
        name: 'chat',
        path: 'chat',
        meta: { title: 'Chat' },
        component: () => import('@/pages/crm/chat/ChatLayout.vue'),
        children: [
          {
            name: 'chat-conversation',
            path: 'c/:id',
            component: () => import('@/pages/crm/chat/ChatView.vue'),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              title: 'Conversación',
              saveAsTab: false,
            },
          },
          {
            name: 'chat-profile',
            path: 'profile/:id',
            component: () => import('@/pages/crm/chat/ChatProfile.vue'),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              title: 'Perfil de chat',
            },
          },
        ],
      },

      {
        name: 'calls',
        path: 'calls',
        redirect: { name: 'calls-main' },
        meta: { title: 'LLamadas' },
        props: {
          title: 'LLamadas',
          routes: [],
        },
        children: [
          {
            name: 'calls-main',
            path: '',
            component: () => import('@/pages/crm/calls/CallsPage.vue'),
            meta: {
              title: 'LLamadas',
            },
          },
          {
            name: 'call-details',
            path: ':id',
            component: () => import('@/pages/crm/calls/Details.vue'),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              title: 'Detalles de LLamada',
            },
          },
        ],
      },
    ],
  },
] as RouteRecordRaw[]
