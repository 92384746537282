import { defineStore } from 'pinia'
import { ref, shallowRef, watchEffect } from 'vue'
import JsSIP from 'jssip'
import type { UAConfiguration, RTCSessionEvent } from 'jssip/lib/UA'
import SessionEvent from '../components/SoftPhone/SessionEvent'
import audioPlayer from '../components/SoftPhone/audioPlayer'
import { useFetcher } from '@/utils/fetch'

interface FreePBXUser {
  _id: string
  _password: string
}

export const useSipStore = defineStore('sip', () => {
  const userAgent = shallowRef<JsSIP.UA | null>(null)
  const connectionStatus = ref({ status: false, text: '' })
  const isReconnecting = ref(false) // Para evitar bucles de reconexión continuos

  const PBX_USER_LOCALSTORAGE = 'userPBX'
  const USER_LOCALSTORAGE = 'user'

  //  Método para registrar el usuario SIP
  const registerUser = (user: string, password: string) => {
    const socket = new JsSIP.WebSocketInterface('wss://pbx.daramex.com:8089/ws')
    const config = {
      uri: `${user}@pbx.daramex.com`,
      password,
      sockets: [socket],
      authorization_user: user,
    } as UAConfiguration

    if (userAgent.value) {
      userAgent.value.stop()
      userAgent.value = null
    }

    const ua = new JsSIP.UA(config)
    ua.start()
    userAgent.value = ua

    // Guardar usuario y contraseña en localStorage para reconexión
    localStorage.setItem(PBX_USER_LOCALSTORAGE, user)

    // Eventos de conexión
    ua.on('connected', () => {
      console.log('connected')
      connectionStatus.value = { status: true, text: '' }
      isReconnecting.value = false // Resetear flag de reconexión exitosa
    })

    ua.on('disconnected', () => {
      console.log('disconnected')
      connectionStatus.value = { status: false, text: '' }
    })

    ua.on('connecting', () => {
      console.log('connecting')
      connectionStatus.value = { status: false, text: '' }
    })

    // Eventos de registro
    ua.on('registered', () => {
      console.log('registered')
      connectionStatus.value = { status: true, text: `${user}@pbx.daramex.com` }
    })

    ua.on('unregistered', () => {
      console.log('unregistered')
      connectionStatus.value = { status: false, text: 'unregistered' }
    })

    ua.on('registrationFailed', (data: any) => {
      console.log('registrationFailed', data)
      connectionStatus.value = { status: false, text: data.cause }
    })

    ua.on('newRTCSession', (data: RTCSessionEvent) => {
      data.originator === 'local' && audioPlayer.play('ringback', 1.0)
      console.log('newRTCSession', data)
      SessionEvent(data.session)
    })
  }

  //  Método para desconectar al usuario SIP
  const disconnectUser = () => {
    if (userAgent.value) {
      console.log('Stopping SIP connection...')
      userAgent.value.stop()
    }

    localStorage.removeItem(PBX_USER_LOCALSTORAGE)
    userAgent.value = null
  }

  //WatchEffect para reintentar conexión si se desconecta
  watchEffect(async () => {
    if (!connectionStatus.value.status /*&& connectionStatus.value.text === 'unregistered'*/ && !isReconnecting.value) {
      console.log('Detectado desconectado, intentando reconectar...')
      isReconnecting.value = true // Evitar múltiples intentos simultáneos

      const userId = localStorage.getItem(USER_LOCALSTORAGE)
      const userPBX = localStorage.getItem(PBX_USER_LOCALSTORAGE)
      if (userId) {
        //free_pbx
        const { error, data } = await useFetcher().Fetch<FreePBXUser>(`crm/user/${userId}/free_pbx`, {
          method: 'GET',
        })

        if (error) {
          console.log('error ', error)
          return false
        } else if (data) {
          if (userPBX && data?._password) {
            setTimeout(() => {
              console.log('Intentando reconectar...')
              registerUser(userPBX, data?._password)
            }, 3000) // Espera 3 segundos antes de intentar la reconexión
          }
        }
      }
    }
  })

  return { userAgent, registerUser, disconnectUser, connectionStatus }
})
