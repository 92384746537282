import { RouteRecordRaw } from 'vue-router'
import { Claims } from '../../utils/enumsHandler'

export default [
  {
    name: 'developer',
    path: 'developer',
    // component: () => import('../../components/TabsView.vue'),
    redirect: { name: 'developer-main' },
    props: {
      title: 'Acceso de desarollador',
      routes: [],
    },
    meta: {
      title: 'Acceso de desarollador',
      requiredClaims: [Claims.AssignDevAccess],
    },
    children: [
      {
        meta: {
          title: 'Acceso de desarollador',
          requiredClaims: [Claims.AssignDevAccess],
        },
        name: 'developer-main',
        path: '',
        component: () => import('../../pages/dev-access/DevAccessPage.vue'),
      },
      {
        meta: {
          title: 'Editar Acceso de desarollador',
          requiredClaims: [Claims.AssignDevAccess],
        },
        name: 'developer-detail',
        path: ':id',
        component: () => import('../../pages/dev-access/CreateOrModify.vue'),
        //New
        props: (route) => ({
          isNew: false,
          elementId: route.params.id,
          key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
      {
        meta: {
          title: 'Crear Acceso de desarollador',
          requiredClaims: [Claims.AssignDevAccess],
        },
        name: 'developer-creation',
        path: 'creation',
        component: () => import('../../pages/dev-access/CreateOrModify.vue'),
        //New
        props: {
          isNew: true,
          key: 'new', // clave única para la creación
        },
      },
    ],
  },
] as RouteRecordRaw[]
