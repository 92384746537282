import { UUID } from 'crypto'
import { TicketPriority } from './ticketService'
import { UserModel } from '../users/userService'

export enum TicketReminder {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export enum TicketStatus {
  Active = 'Active',
  Done = 'Done',
}

export enum InteractionChannel {
  Phone = 'Phone',
  Email = 'Email',
  Website = 'WebSite',
  SocialNetwork = 'SocialNetwork',
}
export enum InteractionType {
  Incident = 'Incident',
  Call = 'Call',
  Email = 'Email',
  Chat = 'Chat',
  Meeting = 'Meeting',
}
export interface Ticket {
  _id: UUID
  createdAt: TimeStampString // Date
  updatedAt: TimeStampString //Date
  _v: number
  priority: TicketPriority
  notes: string
  userInChargeId: UUID
  userId: UUID
  remainder: TicketReminder
  status: TicketStatus
  archived: boolean
  expirationDate: TimeStampString //Date
}
export interface ChatMessage extends PersistentEntity {
  body: string
  fromSupport: true
  chatId: UUID
  supportUserId: UUID
}

export interface Chat extends PersistentEntity {
  userId: UUID | null
  guestUserId: UUID | null
  rating: ChatRating | null
  status: ChatStatus
  source: ChatSource
  supportUnreadMessages: number
  clientUnreadMessages: number
}
export enum ChatSource {
  Telegram = 'Telegram',
  Web = 'Web',
}
export enum ChatStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  RATED = 'RATED',
}
export interface ChatRating extends PersistentEntity {
  rate: number
  isProblemSolved: boolean
  comments: string
  answeringTimeOpinion: string
  chatId: string
}

export interface ChatRatingFilteredWithChat extends ChatRating {
  chat: Chat
}

export interface GuestUser extends PersistentEntity {
  fullName: string
}

export interface ChatFiltered extends Chat {
  messages: ChatMessage[]
  user: UserModel | GuestUser
}
export interface ChatFilteredWithUser extends ChatFiltered {
  userId: UUID
  guestUserId: null
  user: UserModel
}
export interface ChatFilteredWithGuest extends ChatFiltered {
  userId: null
  guestUserId: UUID
  guestUser: GuestUser
}
export type TimeStampString = string //'2025-01-08T22:32:37.237Z'
export interface Interaction extends PersistentEntity {
  userId: UUID
  user: UserModel
  agentId: UUID
  agent: SupportUser
  stage: InteractionStage
  type: InteractionType
  channel: InteractionChannel
  subject: string //'Primera interacción'
  description: string //'Esta es la primera interacción'
  stageId: UUID
  attachments: string[] //['https://example.com/attack.rar']
  tickets: Ticket[]
  metadata: { [key: string]: any }
}

export interface InteractionHistoryResponse {
  items: Interaction[]
  currentPage: number
  limit: number
  totalPages: number
}
export interface InteractionStageUI {
  id: UUID
  label: string
  themeHue: number //hsl hue 0 - 255
}
export interface InteractionStage extends PersistentEntity {
  name: string
  color: string //'#0f0'
  description: string
  index: number //positive int
  interactions: Interaction[]
}
export interface RequestUIData {
  status: RequestUIStatus
  errorMessage: null | string
}
export enum RequestUIStatus {
  Pending = 'pending',
  Success = 'success',
  Idle = 'idle',
  Error = 'error',
}
export interface SupportUser extends PersistentEntity {
  userId: UUID
  user: UserModel
  isActive: boolean
}
export interface PersistentEntity {
  _id: UUID
  _v: number
  createdAt: TimeStampString
  updatedAt: TimeStampString
  archived: boolean
}

export enum CallType {
  Internal = 'Internal',
  ExternalIncoming = 'ExternalIncoming',
  ExternalOutgoing = 'ExternalOutgoing',
}

export enum CallStatus {
  Ringing = 'Ringing',
  InProgress = 'InProgress',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Failed = 'Failed',
  NoAnswer = 'NoAnswer',
}

export interface CallRecord {
  _id: string
  originExtension: string
  destinationExtension: string
  durationInSeconds: number
  externalNumber: string
  agencyNumber: string
  type: CallType
  notes: string
  status: CallStatus
  createdAt: TimeStampString
  updatedAt: TimeStampString
}
