// eventBus.ts
import { reactive } from 'vue'

export const eventBus = reactive({
  refreshRequested: false,
  refreshTagRequested: false,
  refreshServiceRequested: false,
  refreshPropertyRequested: false,
  refreshSaleRequested: false,
  refreshRentRequested: false,
  numberToCall: '',
})
