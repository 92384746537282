<template>
  <div class="flex place-items-center px-4 gap-4 relative overflow-auto pt-2 pb-4 bg-light-surface-container">
    <TransitionGroup name="tabtransition" @beforeLeave="beforeLeave">
      <!--class="px-2 py-1 bg-[var(--va-background-secondary)] rounded-md inline-flex place-items-center shadow-lg font-bold cursor-pointer transition-all duration-500 whitespace-nowrap select-none"
 -->
      <!-- <AppLayoutNavigation /> -->
      <!-- <div
        key="/dashboard"
        class="tab-item"
        :class="'/dashboard' === route.path ? ['selected-item'] : ['non-selected-item']"
        @click="router.push({ path: '/dashboard' })"
      >
        <span> Dashboard </span>
      </div> -->
      <!--class="block px-2 bg-[var(--va-background-secondary)] dark:black rounded-md inline-flex place-items-center shadow-lg font-bold h-full cursor-pointer transition-all duration-500 whitespace-nowrap select-none"
 -->
      <div
        v-for="(tab, index) in tabs"
        :key="tab.path"
        :to="{ path: tab.path }"
        class="group rounded-lg inline-flex pl-3 items-center font-semibold transition-all whitespace-nowrap cursor-pointer active:brightness-95 hover:brightness-105 hover:border-primary active:bg-dark-high-contrast-secondary-fixed gap-1 active:translate-y-1 border"
        :class="
          tab.path === route.path
            ? [
                'bg-dark-high-contrast-secondary-fixed',
                'border-dark-high-contrast-secondary-fixed',
                'text-light-on-secondary-container',
              ]
            : [' border-outline-variant hover:bg-dark-secondary-fixed']
        "
        @click="router.push({ path: tab.path })"
      >
        <span>{{ tab.name }} </span>
        <VaButton
          class="opacity-70 group-hover:opacity-100"
          icon="close"
          round
          preset="secondary"
          @click.stop="closeTab(index)"
        />
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '../../stores/global-store'
import { storeToRefs } from 'pinia'
// import { useColors } from 'vuestic-ui'

const route = useRoute()
const router = useRouter()
const { tabs } = storeToRefs(useGlobalStore())

// const { currentPresetName } = useColors()

const closeTab = (tabIndex: number) => {
  const [removedTab] = tabs.value.splice(tabIndex, 1)

  if (route.path === removedTab.path) {
    router.push({
      path: tabs.value.length === 0 ? '/dashboard' : tabs.value[tabs.value.length - 1].path,
    })
  }
}

const beforeLeave = (ele: Element): void => {
  const el = ele as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style lang="scss">
.tabtransition-move,
/* apply transition to moving elements */
.tabtransition-enter-active,
.tabtransition-leave-active {
  transition: all 0.3s ease;
}

.tabtransition-enter-from {
  opacity: 0;
  transform: translateX(200px) scaleX(2);
}

.tabtransition-enter-to {
  opacity: 1;
  transform: translateX(0px);
}

.tabtransition-leave-from {
  opacity: 1;
}

.tabtransition-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.tabtransition-leave-active {
  position: absolute;
}
</style>
