import { CreateInteractionModel } from '../../../services/crm/interactionService'
import {
  InteractionChannel,
  InteractionType,
  RequestUIData,
  RequestUIStatus,
  Ticket,
} from '../../../services/crm/types'

export const DATATRANSFER_DRAG_DROP_INTERACTION_KEY = 'interactionId'

export const interactionTypes: { label: string; value: InteractionType }[] = [
  { label: 'Email', value: InteractionType.Email },
  { label: 'Llamada', value: InteractionType.Call },
  { label: 'Incidente', value: InteractionType.Incident },
  { label: 'Reunión', value: InteractionType.Meeting },
  { label: 'Chat', value: InteractionType.Chat },
]
export const interactionChannels: { label: string; value: InteractionChannel }[] = [
  { label: 'Email', value: InteractionChannel.Email },
  { label: 'Teléfono', value: InteractionChannel.Phone },
  { label: 'Red social', value: InteractionChannel.SocialNetwork },
  { label: 'Web', value: InteractionChannel.Website },
]
export const BASE_INTERACTION_CREATE: CreateInteractionModel = {
  userId: '358d6ff2-acc7-48a5-a555-7c001c65088c',
  agentId: 'c8851071-98ad-4a9f-8331-212d57105185',
  type: InteractionType.Call,
  channel: InteractionChannel.Phone,
  subject: '',
  description: '',
  stageId: 'adbe274b-fb63-432b-8426-97e54e24dcfb',
  attachments: [],
  ticketsIds: [],
  metadata: {},
}
export const BASE_INTERACTION_CREATE_UI_FORM_DATA: InteractionCreateUIFormData = {
  userId: '358d6ff2-acc7-48a5-a555-7c001c65088c',
  agentId: 'c8851071-98ad-4a9f-8331-212d57105185',
  type: InteractionType.Call,
  channel: InteractionChannel.Phone,
  subject: '',
  description: '',
  stageId: 'adbe274b-fb63-432b-8426-97e54e24dcfb',
  attachments: [],
  tickets: [],
  metadata: {},
}
export interface InteractionCreateUIFormData extends Omit<Partial<CreateInteractionModel>, 'ticketsIds'> {
  tickets?: Ticket[]
}
export function isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}
export const INITIAL_REQUEST_UI_DATA: RequestUIData = { status: RequestUIStatus.Idle, errorMessage: null }
