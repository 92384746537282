import type { IGetElements, IDetails, IUpdateable, ICreate, IRemove, DynamicFilter } from '../commonInterfaces'
import { useFetcher } from '../../utils/fetch'
import { CallRecord } from './types'
const CALLS_BASE_URL = 'crm/CallRecord/'

export interface CallModel extends CallRecord {}
export interface CreateCallModel extends Omit<Partial<CallModel>, '_id' | '_v' | 'createdAt' | 'updatedAt'> {}
export interface UpdateCallModel extends CreateCallModel {}

export interface ICallModel
  extends IGetElements<CallModel>,
    IDetails<CallModel>,
    ICreate<CreateCallModel, CallModel>,
    IUpdateable<CreateCallModel>,
    IRemove {}

export const useCallService = () => {
  const GetAll = async (page: number, pageSize: number, filter: DynamicFilter) => {
    return await useFetcher().Fetch(`${CALLS_BASE_URL}all`, {
      method: 'POST',
      body: {
        page: page,
        limit: pageSize,
        filter: filter,
        orderBy: { created_at: 'DESC' },
      },
    })
  }

  const GetDetails = async (id: string) => {
    return await useFetcher().Fetch(`${CALLS_BASE_URL}${id}`, { method: 'GET' })
  }

  const Update = async (id: string, data: UpdateCallModel) => {
    return await useFetcher().Fetch(`${CALLS_BASE_URL}${id}`, {
      method: 'PUT',
      body: {
        ...data,
      },
    })
  }

  const Remove = async (id: string) => {
    return await useFetcher().Fetch(CALLS_BASE_URL + id, {
      method: 'DELETE',
      body: { id },
    })
  }

  const Create = async (elem: CreateCallModel) => {
    return await useFetcher().Fetch(CALLS_BASE_URL, {
      method: 'POST',
      body: elem,
    })
  }

  return {
    Create,
    GetDetails,
    GetAll,
    Update,
    Remove,
  } as ICallModel
}
