import { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: 'notifications',
    name: 'notifications',
    redirect: { name: 'notifications-main' },
    meta: {
      title: 'Notificaciones',
    },
    props: {
      title: 'Notificaciones',
      routes: [],
    },
    children: [
      {
        name: 'notifications-main',
        path: '',
        meta: {
          title: 'Notificaciones',
        },
        component: () => import('../../pages/notifications/NotificationsPage.vue'),
      },
      {
        name: 'notification-detail',
        path: ':id',
        meta: {
          title: 'Detalles de Notificacion',
          // requiredClaims: [Claims.ReadRoles],
        },
        component: () => import('../../pages/notifications/CreateOrModify.vue'),
        props: (route) => ({
          //isNew: false,
          ownProps: {
            isNew: false,
          },
          elementId: route.params.id,
        }),
      },
    ],
  },
] as RouteRecordRaw[]
