import { PartialGlobalConfig } from 'vuestic-ui/web-components'

const colors: PartialGlobalConfig['colors'] = {
  variables: {
    primary: '#533B72',
    secondary: '#ff7123',
    'secondary-30': '#351000',
    'secondary-dark': '#ca5000',
    'secondary-70': '#ff7123',
    'secondary-80': '#FFB595',
    'neutral-945': '#F5F0F6',
  },

  presets: {
    // key: { primary: '#533B72', secondary: '#ff7123' },
    light: {
      surface: '#FFF7FF',
      primaryContainer: '#EEDCFF',
      surfaceContainer: '#f5eff6',
      backgroundPrimary: '#FFF7FF',
      backgroundSecondary: '#F5F0F6',
      backgroundCardPrimary: '#EEDCFF',
      backgroundCardSecondary: '#FFDBCC',
      success: '#228200',
      info: '#158DE3',
      danger: '#E42222',
      warning: '#FFD43A',
      primary: '#533B72',
      onPrimary: '#ffffff',
      onSecondary: '#ffffff',
      onSecondaryContainer: '#351000',
      secondary: '#ff7123',
      backgroundElement: '#F5F0F6', //M3/sys/light/surface-container
      // backgroundBorder: '#DEE5F2',
      // textPrimary: '#262824',
      // textInverted: '#FFFFFF',
      // shadow: 'rgba(0, 0, 0, 0.12)',
      // focus: '#49A8FF',
      // transparent: 'rgba(0, 0, 0, 0)',
      // backgroundLanding: '#f4f9fc',
      // backgroundLandingBorder: 'rgba(155, 179, 206, 0.8)',
      // backgroundSidebar: '#ECF0F1',
    },
    dark: {
      primary: '#D7BAFB',
      onPrimary: '#3C255A',
      secondary: '#FFB595',
      onSecondary: '#542105',
      backgroundCardPrimary: '#111827',
      backgroundCardSecondary: '#0f172a',
    },
  },
}
export default colors
