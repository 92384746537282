import type { IGetElements, DynamicFilter } from '../commonInterfaces'
import { useFetcher } from '../../utils/fetch'
import { ChatMessage } from './types'
const MSG_BASE_URL = 'crm/chat_message/'

export interface MsgModel extends ChatMessage {}

export interface UpdateMsgModel extends CreateMsgModel {}

export interface MsgModelMethods extends IGetElements<MsgModel> {}
export interface CreateMsgModel
  extends Omit<Partial<MsgModel>, 'archived' | '_id' | '_v' | 'createdAt' | 'updatedAt' | 'isActive'> {}

export const useMsgService = () => {
  const GetAll = async (
    page: number = 1,
    pageSize: number = 1000,
    filter: DynamicFilter = { chat: {} },
    orderBy: { [key: string]: any } = {},
  ) => {
    return await useFetcher().Fetch(`${MSG_BASE_URL}all`, {
      method: 'POST',
      body: {
        page: page,
        limit: pageSize,
        filter: filter,
        orderBy: orderBy,
      },
    })
  }

  return {
    GetAll,
  } as MsgModelMethods
}
