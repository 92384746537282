import type { IGetElements, DynamicFilter, ICreate } from '../commonInterfaces'
import { useFetcher } from '../../utils/fetch'
import { Chat } from './types'
const MSG_BASE_URL = 'crm/chat/'

export interface ChatModel extends Chat {}

export interface ChatModelMethods extends IGetElements<ChatModel>, ICreate<CreateChatModel, ChatModel> {
  MarkAsRead: (id: string) => Promise<any>
}

export interface CreateChatModel
  extends Omit<Partial<ChatModel>, 'archived' | '_id' | '_v' | 'createdAt' | 'updatedAt'> {}

export const useChatService = () => {
  const GetAll = async (
    page: number = 1,
    pageSize: number = 1000,
    filter: DynamicFilter = { user: {} },
    orderBy: { [key: string]: any } = {},
  ) => {
    return await useFetcher().Fetch(`${MSG_BASE_URL}all`, {
      method: 'POST',
      body: {
        page: page,
        limit: pageSize,
        filter: filter,
        orderBy: orderBy,
      },
    })
  }

  const Create = async (elem: CreateChatModel) => {
    console.log(elem)

    return await useFetcher().Fetch(MSG_BASE_URL, {
      method: 'POST',
      body: elem,
    })
  }

  const MarkAsRead = async (id: string) => {
    return await useFetcher().Fetch(`${MSG_BASE_URL}${id}/mark-as-read`, {
      method: 'POST',
    })
  }

  return {
    GetAll,
    Create,
    MarkAsRead,
  } as ChatModelMethods
}
