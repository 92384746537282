import { io, Socket } from 'socket.io-client'
import { onBeforeUnmount, ref } from 'vue'
import { ChatMessage } from './types'
import { UUID } from 'crypto'
import { DOMAIN } from '../../environment'

export const useChatSocket = () => {
  const socket = ref<Socket>()
  const isConnected = ref(false)

  const initializeSocket = async (token: string, actions?: { [key: string]: (...args: any[]) => void }) => {
    console.info('Inicializando chat socket', token?.substring(0, 10))
    socket.value = io(`https://crmws.${DOMAIN}`, {
      query: { token },
      path: '/socket.io',
      transports: ['websocket'],
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
    })
    console.info('Socket inicializado: ', socket.value)
    setupSocketListeners(actions)
  }

  const setupSocketListeners = (actions?: { [key: string]: (...args: any[]) => void }) => {
    if (!socket.value) return

    // Existing listeners remain unchanged
    socket.value.on('connect', () => {
      console.log('Socket connected')

      isConnected.value = true
    })

    socket.value.on('disconnect', (reason: string) => {
      isConnected.value = false
      console.log('Disconnected:', reason)
    })

    socket.value.on('chat-error', (error: any) => {
      console.error('Chat error:', error)
    })

    socket.value.on('exception', (data: any) => {
      console.error('Server exception:', data)
    })

    // Add dynamic listeners based on actions
    if (actions && typeof actions === 'object') {
      Object.entries(actions).forEach(([eventName, handler]) => {
        if (typeof handler !== 'function' || !socket.value) return

        const listener = (...args: any[]) => {
          try {
            handler(...args)
          } catch (error) {
            console.error(`Error handling ${eventName}:`, error)
          }
        }
        socket.value.on(eventName, listener)

        // Cleanup function to remove listeners when component unmounts
        onBeforeUnmount(() => {
          socket.value?.off(eventName, listener)
        })
      })
    }
  }

  const sendMessage = async (messagePayload: ChatMessagePayload) => {
    console.log('Socket send called. Message payload: ', messagePayload)

    if (!messagePayload.userId) throw new Error('Invalid message payload. No userId')
    if (!messagePayload.jwt) throw new Error('Invalid message payload. No user token')
    if (!socket.value || !messagePayload.body.trim()) throw new Error('Invalid message body')
    console.info('Message payload valid. Proceeding..')

    return new Promise<ChatMessageServerResponse>((resolve, reject) => {
      socket.value?.emit('chat', messagePayload, (response: ChatMessageServerResponse) => {
        console.log('Server response:', response)
        if (response) resolve(response)
        else reject()
      })
    })
  }

  const disconnect = () => {
    if (socket.value) {
      socket.value.close()
      isConnected.value = false
    }
  }

  return {
    socket,
    isConnected,
    initializeSocket,
    sendMessage,
    disconnect,
  }
}

export interface ChatMessageServerResponse {
  success: boolean
  data?: ChatMessage
  error?: {
    message: string
  }
}
export interface ChatMessagePayload {
  userId: UUID //Client ID always
  body: string //Message text body
  jwt: string //sender's auth token
}
