import { useMsgService } from '@/services/crm/msgService'
import { ChatFiltered, ChatMessage } from '@/services/crm/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import useRequestUiData from '../common/useRequestUiData'
import { CreateChatModel, useChatService } from '@/services/crm/chatService'
import { UUID } from 'crypto'

export const useChatStore = defineStore('chat', () => {
  // State
  const messages = ref<ChatMessage[]>([])
  const chats = ref<ChatFiltered[]>([])
  const activeConversation = ref<string | null>(null)
  const conversations = ref<Record<string, ChatMessage[]>>({})
  const isLoading = ref(false)
  const error = ref<string | null>(null)
  const {
    loading: chatsLoading,
    requestData: chatsRequestUiData,
    setPending: setChatsPending,
    setSuccess: setChatsSuccess,
    setRequestError: setChatsError,
  } = useRequestUiData()
  // Getters
  const chatService = useChatService()
  const loadMessages = async (page: number = 1, pageSize: number = 1000) => {
    isLoading.value = true
    error.value = null
    console.log('Need to load messages. Proceeding.')
    try {
      const msgService = useMsgService()
      const response = await msgService.GetAll(page, pageSize)
      if (response.error) throw new Error(response.error.message)
      // Update messages array
      messages.value = response.data.items

      // Organize messages by conversation
      conversations.value = response.data.items.reduce(
        (acc, msg) => {
          const chatId = msg.chatId
          if (!acc[chatId]) {
            acc[chatId] = []
          }
          acc[chatId].push(msg)
          return acc
        },
        {} as Record<string, ChatMessage[]>,
      )
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'Failed to load messages'
    } finally {
      isLoading.value = false
    }
  }

  const loadChats = async (page: number = 1, pageSize: number = 100) => {
    setChatsPending()

    console.log('Want to load chats. Proceeding.')
    try {
      console.info('TRY')
      const response = await chatService.GetAll(page, pageSize, { user: {}, messages: {} })
      console.info('Chats response received: ', response)
      if (response.error) {
        console.error('Error loading chats ', error)
        setChatsError(response.error)
        throw new Error(response.error.message)
      } else if (response.data) {
        setChatsSuccess()
        console.log('Load successful: ', response.data)
        // Update chats array
        chats.value = response.data.items as ChatFiltered[]

        console.log('Chats updated: ', chats.value)
      }
    } catch (err) {
      console.log('CATCHED and error:', err)
      setChatsError(err instanceof Error ? err : new Error('Failed to load chats'))
    }
  }
  //   const hasUnreadMessages = computed((userId: string) => conversations.value[userId]?.some((msg) => !msg.isRead))

  // Actions
  const addMessage = (message: ChatMessage) => {
    if (!message.chatId) return
    // const chatToAdd = getChatById(message.chatId)
    //TODO
  }

  const setActiveConversation = (userId: string) => {
    activeConversation.value = userId
    // if (conversations.value[userId]) {
    //   conversations.value[userId].forEach((msg) => {
    //     msg.isRead = true
    //   })
    // }
  }
  const getChatById = (id: UUID) => {
    return chats.value.find((chat) => chat._id === id)
  }
  const getOrFetchChatById = (id: UUID) => {
    return chats.value.find((chat) => chat._id === id)
  }
  const getMessagesByChatId = async (id: UUID) => {
    if (!chats.value.length) await loadChats()
    return chats.value.find((chat) => chat._id === id)?.messages
  }
  const clearConversation = (userId: string) => {
    conversations.value[userId] = []
  }
  // const availableUsersForCreateChat = computed(() =>
  //   users.value.filter(
  //     (user) => !chats.value.some((chat) => chat.userId === user._id || chat.guestUserId === user._id),
  //   ),
  // )

  const createChat = async (data: CreateChatModel) => await chatService.Create(data)

  // Computed property to sort chats by the most recent message
  const sortedChatsByRecentMessage = computed(() => {
    return chats.value.slice().sort((a, b) => {
      // Get the last message timestamp for chat A
      const lastMessageA = a.messages[a.messages.length - 1]?.createdAt || '0'
      // Get the last message timestamp for chat B
      const lastMessageB = b.messages[b.messages.length - 1]?.createdAt || '0'
      // Compare timestamps in descending order (most recent first)
      return new Date(lastMessageB).getTime() - new Date(lastMessageA).getTime()
    })
  })

  // Return all public properties
  return {
    getChatById,
    getOrFetchChatById,
    messages,
    activeConversation,
    conversations,
    getMessagesByChatId,
    // hasUnreadMessages,
    loadMessages,
    addMessage,
    setActiveConversation,
    clearConversation,
    chats,
    sortedChatsByRecentMessage,
    chatsLoading,
    chatsRequestUiData,
    loadChats,
    createChat,
    // availableUsersForCreateChat,
  }
})
