<template>
  <VaInnerLoading :loading="loading" :size="60" class="w-full h-full">
    <VaLayout
      :top="{ fixed: true, order: 1 }"
      :left="{
        fixed: true,
        absolute: breakpoints.mdDown,
        order: 2,
        overlay: breakpoints.mdDown && !isSidebarMinimized,
      }"
      :right="{ fixed: true, absolute: breakpoints.smDown, order: 1, overlay: breakpoints.smDown }"
      @leftOverlayClick="isSidebarMinimized = true"
    >
      <template #left>
        <AppSidebar :minimized="isSidebarMinimized" :animated="!isMobile" :mobile="isMobile" />
      </template>
      <template #right>
        <RightSidebar />
      </template>
      <template #top>
        <AppNavbar :is-mobile="isMobile" />

        <AppLayoutOpenViewsTabs />
      </template>

      <template #content>
        <!-- <div :class="{ minimized: isSidebarMinimized }" class="app-layout__sidebar-wrapper">
          <div v-if="isFullScreenSidebar" class="flex justify-end">
            <VaButton class="px-4 py-4" icon="md_close" preset="plain" @click="onCloseSidebarButtonClick" />
          </div>
        </div> -->
        <!-- <AppLayoutOpenViewsTabs /> -->
        <main class="bg-light-surface rounded-tr-2xl h-[86vh] overflow-auto">
          <RouterView v-slot="{ Component, route }">
            <KeepAlive>
              <component :is="Component" :key="route.path" />
            </KeepAlive>
          </RouterView>
        </main>
      </template>
    </VaLayout>
  </VaInnerLoading>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, provide, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { onBeforeRouteUpdate } from 'vue-router'
import { useBreakpoint, useToast } from 'vuestic-ui'

import { useGlobalStore } from '../stores/global-store'

import AppLayoutOpenViewsTabs from '../components/app-layout-navigation/AppLayoutOpenViewsTabs.vue'
import AppNavbar from '../components/navbar/AppNavbar.vue'
import AppSidebar from '../components/sidebar/AppSidebar.vue'
import RightSidebar from '@/components/sidebar/SecondarySidebar.vue'
import { useAuth } from '@/stores/auth'
import { useChatSocket } from '@/services/crm/useChatSocket'
import { useChatStore } from '@/pages/crm/chat/chatStore'
import router from '@/router'
import { useChatService } from '@/services/crm/chatService'

const GlobalStore = useGlobalStore()

const breakpoints = useBreakpoint()

const sidebarWidth = ref('16rem')
const sidebarMinimizedWidth = ref()

const isMobile = ref(false)
const isTablet = ref(false)
const { isSidebarMinimized, loading } = storeToRefs(GlobalStore)

const onResize = () => {
  console.log(breakpoints)
  isSidebarMinimized.value = breakpoints.mdDown
  isMobile.value = breakpoints.smDown
  isTablet.value = breakpoints.mdDown
  sidebarMinimizedWidth.value = isMobile.value ? '0' : '4.5rem'
  sidebarWidth.value = isTablet.value ? '100%' : '16rem'
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize()
})
const auth = useAuth()
const { socket, initializeSocket, isConnected: isChatSocketConnected, sendMessage } = useChatSocket()

const { notify } = useToast()
const chatStore = useChatStore()
const chatService = useChatService()
provide('isChatSocketConnected', isChatSocketConnected)
provide('sendChatMessage', sendMessage)
provide('chatSocket', socket)
onMounted(async () => {
  if (auth.Token)
    await initializeSocket(auth.Token, {
      chat: async (msg: any) => {
        console.info('A message has been received: ', msg)

        if (!msg.fromSupport) notify({ title: 'Nuevo mensaje', message: msg.body, color: 'primary' })
        //TODO: optimistic update, and later load chats

        if (router.currentRoute.value.name == 'chat-conversation' && router.currentRoute.value.params.id === msg.chatId)
          await chatService.MarkAsRead(msg.chatId)

        chatStore.loadChats()
      },
    })
  // sendMessage({fromUserId:socket.value?.id,userId: socket.value?.id, body:"sendMessage fijo"})
  // if (socket.value)
  //   socket.value.on('chat', (msg) => {
  //     notify({ title: 'Nuevo mensaje', message: msg.props.body, color: 'primary' })
  //     chatStore.loadMessages()
  //   })
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

onBeforeRouteUpdate(() => {
  if (breakpoints.mdDown) {
    // Collapse sidebar after route change for Mobile
    isSidebarMinimized.value = true
  }
})

// const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

// const onCloseSidebarButtonClick = () => {
//   isSidebarMinimized.value = true
// }
</script>

<style lang="scss" scoped>
// Prevent icon jump on animation
.va-sidebar {
  width: unset !important;
  min-width: unset !important;
}
</style>
