import { RequestUIData, RequestUIStatus } from '@/services/crm/types'
import { reactive, computed } from 'vue'
import { INITIAL_REQUEST_UI_DATA } from '../interactions/interactionUtils'

export default () => {
  const requestData = reactive<RequestUIData>({ ...INITIAL_REQUEST_UI_DATA }) //if ref, cuases reactivity issues and mutates before rendering
  const setPending = () => {
    requestData.status = RequestUIStatus.Pending
    requestData.errorMessage = null
  }
  const setSuccess = () => {
    requestData.status = RequestUIStatus.Success
    requestData.errorMessage = null
  }
  const setRequestError = (error: Error) => {
    requestData.status = RequestUIStatus.Error
    requestData.errorMessage = error.message ?? error
  }
  const loading = computed(() => requestData.status === RequestUIStatus.Pending)

  return {
    requestData,
    loading,
    setPending,
    setSuccess,
    setRequestError,
  }
}
