import { INavigationRoute } from '@/components/sidebar/NavigationRoutes'
import { Claims } from '@/utils/enumsHandler'

export default {
  root: {
    name: 'crm',
    displayName: 'navigationRoutes.crm',
    meta: {
      icon: 'group',
      title: 'Clients',
      requiredClaims: [],
    },
  },
  routes: [
    {
      name: 'agents',
      displayName: 'crm.agents',
      meta: {
        icon: 'mso-groups',
        title: 'Agentes',
        requiredClaims: [Claims.ReadSupportUser],
      },
    },
    {
      name: 'interactions',
      displayName: 'crm.interactions',
      meta: {
        icon: 'mso-notifications',
        title: 'Interacciones',
        requiredClaims: [Claims.ReadInteractionsHistory],
      },
    },
    {
      name: 'chat',
      displayName: 'crm.chat',
      meta: {
        icon: 'mso-chat_bubble  ',
        title: 'Chat',
        requiredClaims: [Claims.ReadChats],
      },
    },
    // {
    //   name: 'calls',
    //   displayName: 'crm.calls',
    //   meta: {
    //     icon: 'mso-phone',
    //     title: 'LLamadas',
    //   },
    // },
  ] as INavigationRoute[],
}
