<template>
  <div class="keypad-container">
    <!-- Input con icono de borrar -->
    <div class="input-wrapper">
      <VaInput v-model="phoneNumber" placeholder="Ingrese número" class="text-xl" readonly>
        <template #appendInner>
          <VaButton
            v-show="phoneNumber"
            icon="mso-backspace"
            color="#0006"
            size="20px"
            preset="secondary"
            round
            @click="deleteLastDigit"
          />
        </template>
      </VaInput>
    </div>

    <!-- Teclado numérico -->
    <div class="keypad">
      <VaButton
        v-for="num in keys"
        :key="num"
        round
        preset="secondary"
        class="key active:scale-95 transition-all"
        :color="/^\d$/.test(num) ? 'primary' : '#0007'"
        @click="addDigit(num)"
      >
        <span class="text-2xl font-thin">{{ num }}</span>
      </VaButton>
    </div>

    <!-- Botón de llamada -->
    <VaButton icon="mso-phone" class="call-button" color="success" round @click="makeCall"> Llamar </VaButton>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const emit = defineEmits(['call']) // Define el evento 'call'
const phoneNumber = ref('')

const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '0', '#']

const addDigit = (num) => {
  phoneNumber.value += num
}

const deleteLastDigit = () => {
  phoneNumber.value = phoneNumber.value.slice(0, -1)
}

const makeCall = () => {
  if (phoneNumber.value) {
    console.log(`Llamando a ${phoneNumber.value}`)
    emit('call', phoneNumber.value) // Emitir el evento con el número marcado
  }
}
</script>

<style scoped>
.keypad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.input-wrapper {
  width: 100%;
  max-width: 250px;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
  max-width: 250px;
}

.key {
  width: 100%;
  height: 50px;
}

.call-button {
  margin-top: 10px;
  width: 80%;
}
</style>
