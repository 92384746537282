import { useAuth } from '../stores/auth'
import { Claims } from './enumsHandler'

export function usePermissions() {
  const authStore = useAuth()
  const hasPermission = (claim: string) => {
    if (authStore.Claims.includes(Claims.SuperAccess)) return true
    else return authStore.Claims.includes(claim)
  }
  return { hasPermission }
}
