<template>
  <div class="profile-dropdown-wrapper">
    <!-- v-model="isShown" -->
    <VaDropdown :offset="[9, 0]" class="profile-dropdown" stick-to-edges>
      <template #anchor>
        <button class="flex gap-4 items-center px-4 group transition-all">
          <div
            v-if="userStore.user"
            class="text-end transition-transform group-active:translate-x-0.5 group-hover:-translate-x-0.5 text-light-on-surface"
          >
            <p class="text-base hidden sm:block">
              {{ userStore.fullName }}
            </p>
            <p class="sm:hidden text-base">
              {{ userStore.user.firstName.split(' ')[0] }}
            </p>
            <p
              class="text-xs flex items-center justify-end gap-0.5 group-active:translate-x-0 group-hover:-translate-x-0.5 transition-transform"
            >
              <small class="text-primary">@</small>{{ userStore.userName }}
            </p>
          </div>
          <span class="profile-dropdown__anchor min-w-max">
            <slot />
            <VaAvatar
              class="transition-transform group-hover:scale-105 group-active:scale-95"
              :size="32"
              color="primary"
            >
              <img v-if="userStore.profileImage" :src="userStore.profileImage" />
              <VaIconAccount v-else color="white" width.number="32" heigth.number="32" />
            </VaAvatar>
          </span>
        </button>
      </template>
      <VaDropdownContent
        class="profile-dropdown__content md:w-60 px-0 py-4 w-full"
        :style="{ '--hover-color': hoverColor }"
      >
        <VaList v-for="group in options" :key="group.name">
          <header v-if="group.name" class="uppercase text-[var(--va-secondary)] opacity-80 font-bold text-xs px-4">
            {{ t(`user.${group.name}`) }}
          </header>
          <VaListItem
            v-for="item in group.list"
            :key="item.name"
            class="menu-item px-4 text-base cursor-pointer h-8"
            v-bind="resolveLinkAttribute(item)"
          >
            <VaIcon :name="item.icon" class="pr-1" color="secondary" />
            {{ t(`user.${item.name}`) }}
          </VaListItem>
          <VaListSeparator v-if="group.separator" class="mx-3 my-2" />
        </VaList>
      </VaDropdownContent>
    </VaDropdown>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'
import VaIconAccount from '../../../icons/VaIconAccount.vue'
import { useUserStore } from '@/stores/user-store'

const { colors, setHSLAColor } = useColors()
const hoverColor = computed(() => setHSLAColor(colors.focus, { a: 0.1 }))
const userStore = useUserStore()
const { t } = useI18n()

type ProfileListItem = {
  name: string
  to?: string
  href?: string
  icon: string
}

type ProfileOptions = {
  name: string
  separator: boolean
  list: ProfileListItem[]
}

withDefaults(
  defineProps<{
    options?: ProfileOptions[]
  }>(),
  {
    options: () => [
      {
        name: 'account',
        separator: true,
        list: [
          {
            name: 'profile',
            to: 'preferences',
            icon: 'mso-account_circle',
          },
          {
            name: 'settings',
            to: 'settings',
            icon: 'mso-settings',
          },
        ],
      },
      {
        name: 'explore',
        separator: true,
        list: [
          {
            name: 'helpAndSupport',
            href: 'https://discord.gg/u7fQdqQt8c',
            icon: 'mso-error',
          },
        ],
      },
      {
        name: '',
        separator: false,
        list: [
          {
            name: 'logout',
            to: 'logout',
            icon: 'mso-logout',
          },
        ],
      },
    ],
  },
)

// const isShown = ref(false)

const resolveLinkAttribute = (item: ProfileListItem) => {
  return item.to ? { to: { name: item.to } } : item.href ? { href: item.href, target: '_blank' } : {}
}
</script>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  &__content {
    .menu-item:hover {
      background: var(--hover-color);
    }
  }

  &__anchor {
    display: inline-block;
  }
}
</style>
