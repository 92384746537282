import { useSessionStore } from '../../stores/callSessionStore'
import type { RTCSession } from 'jssip/lib/RTCSession'
import audioPlayer from './audioPlayer'
import { useCallService, CreateCallModel } from '@/services/crm/callService'
import { CallType, CallStatus } from '@/services/crm/types'

const PBX_USER_LOCALSTORAGE = 'userPBX'

const callService = useCallService()

export default function SessionEvent(session: RTCSession) {
  const sessionStore = useSessionStore()

  sessionStore.setLiveSessionArray(session)

  const extractDiversionNumber = (data: string): string => {
    const diversionMatch = data ? data.match(/Diversion:\s*<sip:(\+\d+)@/) : ''
    return diversionMatch ? diversionMatch[1] : ''
  }

  let callRegistred = false
  const audio = new Audio()
  audio.autoplay = true

  //propiedades de la llamada
  const originExtension: string =
    session.direction === 'outgoing' ? session.local_identity.uri.user : session.remote_identity.uri.user
  // let destinationExtension :string = data.originator === 'remote' ? data.session.remote_identity.uri.user : data.session.local_identity.uri.user
  const destinationExtension: string =
    originExtension === session.local_identity.uri.user
      ? session.remote_identity.uri.user
      : localStorage.getItem(PBX_USER_LOCALSTORAGE) ?? ''

  const durationInSeconds: number = 0
  const externalNumber: string = ''
  const agencyNumber: string = extractDiversionNumber((session as any)._request.data)
  const type: CallType = CallType.Internal
  const notes: string = ''
  const status: CallStatus = CallStatus.Ringing
  let callId: string = ''
  const callRegistry: CreateCallModel = {
    originExtension,
    destinationExtension,
    durationInSeconds,
    externalNumber,
    agencyNumber,
    type,
    notes,
    status,
  }

  const createRegistry = async () => {
    if (agencyNumber !== '') {
      callRegistry.type = CallType.ExternalIncoming
      callRegistry.externalNumber = originExtension
      callRegistry.originExtension = ''
    }
    if (localStorage.getItem(PBX_USER_LOCALSTORAGE) === originExtension && destinationExtension.length > 6) {
      callRegistry.type = CallType.ExternalOutgoing
      callRegistry.externalNumber = destinationExtension
      callRegistry.destinationExtension = ''
    }

    const { data, error } = await callService.Create(callRegistry)

    if (error) {
      console.log('error => ', error)
    } else {
      callId = data?._id ?? ''
    }
  }

  session.on('peerconnection', (data: any) => {
    console.log('peerconnection', data)
    // console.log('peerconnection')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('connecting', (data: any) => {
    console.log('connecting', data)
    // console.log('connecting')
    const mediaStream = new MediaStream(
      session.connection.getReceivers().map((receiver) => {
        return receiver.track
      }),
    )
    audio.srcObject = mediaStream
    audio.play()
    sessionStore.setLiveSessionArray(session)
  })

  session.on('sending', (data: any) => {
    console.log('sending', data)
    // console.log('sending')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('progress', async (data: any) => {
    console.log('progress', data)
    // console.log('progress')
    if (!callRegistred) {
      callRegistred = true
      await createRegistry()
    }

    if (session.direction === 'outgoing') {
      audioPlayer.play('ringback', 1.0)
      audioPlayer.setLoop('ringback', true)
    } else {
      audioPlayer.play('ringing', 1.0)
      audioPlayer.setLoop('ringing', true)
    }
    //
    sessionStore.setLiveSessionArray(session)
  })

  session.on('accepted', (data: any) => {
    console.log('accepted', data)
    // console.log('accepted')
    // audioPlayer.stop('ringing')
    if (session.direction === 'outgoing') {
      audioPlayer.stop('ringback')
    } else {
      audioPlayer.stop('ringing')
    }
    sessionStore.setLiveSessionArray(session)
  })

  session.on('confirmed', async (data: any) => {
    console.log('confirmed', data)
    // console.log('confirmed')
    const { originExtension, ...editingCall } = callRegistry
    void originExtension
    editingCall.status = CallStatus.InProgress
    const { error } = await callService.Update(callId, editingCall)
    if (error) {
      console.log('error => ', error)
    }
    sessionStore.setLiveSessionArray(session)
  })

  session.on('ended', async (data: any) => {
    console.log('ended', data) // aqui independiente mente de lo que pase o sea quien cuelge se dispara este evento
    // console.log('ended')
    const { originExtension, ...editingCall } = callRegistry
    void originExtension
    editingCall.status = CallStatus.Completed
    editingCall.durationInSeconds = Math.abs(session.end_time.getTime() - session.start_time.getTime()) / 1000
    const { error } = await callService.Update(callId, editingCall)
    if (error) {
      console.log('error => ', error)
    }
    // audioPlayer.stop('ringing')
    if (session.direction === 'outgoing') {
      audioPlayer.stop('ringback')
    } else {
      audioPlayer.stop('ringing')
    }
    audioPlayer.play('rejected', 1.0)
    sessionStore.setLiveSessionArray(session)
  })

  session.on('failed', async (data: any) => {
    console.log('failed', data) // aqui esto genera llamada perdida ..cdo se levanta por una ext las deas dan failed
    // console.log('failed')
    const { originExtension, ...editingCall } = callRegistry
    void originExtension
    if (data.cause === 'Busy' || data.cause === 'Rejected') editingCall.status = CallStatus.Rejected
    else if (data.cause === 'Canceled') editingCall.status = CallStatus.Cancelled
    else if (data.cause === 'No Answer') editingCall.status = CallStatus.NoAnswer
    else editingCall.status = CallStatus.Failed

    if (callId) {
      const { error } = await callService.Update(callId, editingCall)
      if (error) {
        console.log('error => ', error)
      }
    }
    // audioPlayer.stop('ringing')
    if (session.direction === 'outgoing') {
      audioPlayer.stop('ringback')
    } else {
      audioPlayer.stop('ringing')
    }
    audioPlayer.play('rejected', 1.0)
    sessionStore.setLiveSessionArray(session)
  })

  session.on('newDTMF', (data: any) => {
    console.log('newDTMF', data)
    // console.log('newDTMF')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('newInfo', (data: any) => {
    console.log('newInfo', data)
    // console.log('newInfo')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('hold', (data: any) => {
    console.log('hold', data)
    // console.log('hold')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('unhold', (data: any) => {
    console.log('unhold', data)
    // console.log('unhold')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('muted', (data: any) => {
    console.log('muted', data)
    // console.log('muted')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('unmuted', (data: any) => {
    console.log('unmuted', data)
    // console.log('unmuted')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('reinvite', (data: any) => {
    console.log('reinvite', data)
    // console.log('reinvite')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('update', (data: any) => {
    console.log('update', data)
    // console.log('update')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('refer', (data: any) => {
    console.log('refer', data)
    // console.log('refer')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('replaces', (data: any) => {
    console.log('replaces', data)
    // console.log('replaces')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('sdp', (data: any) => {
    console.log('sdp', data)
    // console.log('sdp')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('icecandidate', (data: any) => {
    console.log('icecandidate', data)
    // console.log('icecandidate')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('getusermediafailed', (data: any) => {
    console.log('getusermediafailed', data)
    // console.log('getusermediafailed',)
    sessionStore.setLiveSessionArray(session)
  })

  session.on('peerconnection:createofferfailed', (data: any) => {
    console.log('peerconnection:createofferfailed', data)
    // console.log('peerconnection:createofferfailed')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('peerconnection:createanswerfailed', (data: any) => {
    console.log('peerconnection:createanswerfailed', data)
    // console.log('peerconnection:createanswerfailed')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('peerconnection:setlocaldescriptionfailed', (data: any) => {
    console.log('peerconnection:setlocaldescriptionfailed', data)
    // console.log('peerconnection:setlocaldescriptionfailed')
    sessionStore.setLiveSessionArray(session)
  })

  session.on('peerconnection:setremotedescriptionfailed', (data: any) => {
    console.log('peerconnection:setremotedescriptionfailed', data)
    // console.log('peerconnection:setremotedescriptionfailed')
    sessionStore.setLiveSessionArray(session)
  })
}
