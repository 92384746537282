import { Claims } from '../../utils/enumsHandler'

export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon?: string; title?: string; requiredClaims?: string[] }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'home',
        title: 'Dashboard',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'mso-person',
        title: 'Usuarios',
        requiredClaims: [Claims.ReadUsers],
      },
    },
    {
      name: 'access',
      displayName: 'menu.access',
      meta: {
        icon: 'mso-lock',
        title: 'Acceso',
        requiredClaims: [Claims.ReadRoles],
      },
    },
    {
      name: 'realestate',
      displayName: 'menu.realestate',
      meta: {
        icon: 'mso-real_estate_agent',
        title: 'Realestate',
        requiredClaims: [
          Claims.ReadConglomerate,
          Claims.ReadProperties,
          Claims.ReadProperties,
          Claims.ReadRentOffer,
          Claims.ReadSaleOffer,
          Claims.ReadRealEstateService,
          Claims.ReadTag,
          Claims.ReadRealEstateSettings,
          Claims.ReadRentAgreement,
          Claims.ReadAdvertiser,
        ],
      },
    },
    {
      name: 'payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'mso-payments',
        title: 'Pagos',
        requiredClaims: [
          Claims.ReadPaymentOperations,
          Claims.ReadPaymentBalanceOperations,
          Claims.ReadMerchant,
          Claims.RefundPayment,
        ],
      },
    },
    {
      name: 'promotions',
      displayName: 'menu.promotions',
      meta: {
        icon: 'mso-label',
        title: 'Promociones',
        requiredClaims: [Claims.ReadGeneralPromotion],
      },
    },
    {
      name: 'services',
      displayName: 'menu.services',
      meta: {
        icon: 'mso-home_repair_service',
        title: 'Servicios',
        requiredClaims: [Claims.ReadGeneralService],
      },
    },
    {
      name: 'agencies',
      displayName: 'menu.agencies',
      meta: {
        icon: 'mso-source_environment',
        title: 'Agencias',
        requiredClaims: [Claims.ReadAgency],
      },
    },
    {
      name: 'shops',
      displayName: 'menu.shops',
      meta: {
        icon: 'mso-store',
        title: 'Tiendas',
        requiredClaims: [
          Claims.ReadShop,
          Claims.ReadProduct,
          Claims.ReadPromotion,
          Claims.ReadCategory,
          Claims.ReadProvider,
          Claims.ReadCupon,
          Claims.ReadOrders,
          Claims.ReadShopSettings,
          Claims.ReadWarehouse,
        ],
      },
      children: [
        {
          name: 'shops-tags',
          displayName: 'menu.shops-tags',
          meta: {
            icon: 'payments',
            title: 'Tags',
            requiredClaims: [Claims.ReadShop],
          },
        },
        {
          name: 'shops-categories',
          displayName: 'menu.shops-categories',
          meta: {
            icon: 'payments',
            title: 'Categorias',
            requiredClaims: [Claims.ReadShop],
          },
        },
        {
          name: 'attributes-main',
          displayName: 'menu.attributes',
          meta: {
            icon: 'payments',
            title: 'Atributos',
            requiredClaims: [Claims.ReadShop],
          },
        },
        {
          name: 'shops-settings',
          displayName: 'menu.shops-settings',
          meta: {
            icon: 'payments',
            title: 'Config',
            requiredClaims: [Claims.ReadShopSettings],
          },
        },
      ],
    },
    {
      name: 'dlq',
      displayName: 'menu.dlq',
      meta: {
        icon: 'payments',
        title: 'Broker',
        requiredClaims: [Claims.ReadDLQMessages],
      },
    },
    {
      name: 'crm',
      displayName: 'menu.crm',
      meta: {
        icon: 'group',
        title: 'CRM',
        requiredClaims: [Claims.ReadInteractionsHistory, Claims.ReadStage],
      },
    },
    {
      name: 'notifications',
      displayName: 'menu.notifications',
      meta: {
        icon: 'notifications',
        title: 'Notificaciones',
        requiredClaims: [Claims.ReadNotifications],
      },
    },

    {
      name: 'app-versions', // app-versions.ts
      displayName: 'menu.app_versions',
      meta: {
        icon: 'android',
        title: 'versiones de la app',
        // requiredClaims: [Claims.ReadDLQ],
      },
    },
    {
      name: 'developer',
      displayName: 'menu.developer',
      meta: {
        icon: 'code',
        title: 'Desarollador',
        requiredClaims: [Claims.AssignDevAccess],
      },
    },
    //{
    //  name: 'payments',
    //  displayName: 'menu.payments',
    //  meta: {
    //    icon: 'credit_card',
    //  },
    //  children: [
    //    {
    //      name: 'payment-methods',
    //      displayName: 'menu.payment-methods',
    //    },
    //    {
    //      name: 'pricing-plans',
    //      displayName: 'menu.pricing-plans',
    //    },
    //    {
    //      name: 'billing',
    //      displayName: 'menu.billing',
    //    },
    //  ],
    //},
    //{
    //  name: 'auth',
    //  displayName: 'menu.auth',
    //  meta: {
    //    icon: 'login',
    //    title: 'LOGIN'
    //  },
    //  children: [
    //    {
    //      name: 'login',
    //      displayName: 'menu.login',
    //      meta: {
    //        title: 'LOGUEO XD'
    //      }
    //    },
    //    {
    //      name: 'signup',
    //      displayName: 'menu.signup',
    //      meta: {
    //        title: 'SIGN UP'
    //      }
    //    },
    //    {
    //      name: 'recover-password',
    //      displayName: 'menu.recover-password',
    //    },
    //  ],
    //},

    //{
    //  name: 'faq',
    //  displayName: 'menu.faq',
    //  meta: {
    //    icon: 'quiz',
    //  },
    //},
    //{
    //  name: '404',
    //  displayName: 'menu.404',
    //  meta: {
    //    icon: 'vuestic-iconset-files',
    //  },
    //},
    //{
    //  name: 'preferences',
    //  displayName: 'menu.preferences',
    //  meta: {
    //    icon: 'manage_accounts',
    //  },
    //},
    //{
    //  name: 'settings',
    //  displayName: 'menu.settings',
    //  meta: {
    //    icon: 'settings',
    //  },
    //},
  ] as INavigationRoute[],
}
