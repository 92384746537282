import { createApp, Directive } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'

import { API_URL } from './environment'
console.log(API_URL, '   API_URL main')

// Third party libraries styles
import 'vue3-carousel/dist/carousel.css'
import 'v-calendar/style.css'

const fallbackImagePath = '/fallback.png' // Ruta global de la imagen fallback

const fallbackImg: Directive = {
  mounted(el) {
    // Verificar si no hay imagen definida o si falla al cargar
    const setFallbackImage = () => {
      el.src = fallbackImagePath
    }

    // Si el atributo `src` está vacío o no definido, establecer directamente el fallback
    if (!el.src || el.src.trim() === '') {
      setFallbackImage()
    }

    // Manejar el evento de error
    el.onerror = setFallbackImage
  },
  updated(el) {
    // Si el atributo `src` cambia a vacío, aplicar el fallback
    if (!el.src || el.src.trim() === '') {
      el.src = fallbackImagePath
    }
  },
}

const app = createApp(App)

// Registrar la directiva globalmente
app.directive('fallback-img', fallbackImg)

app.use(stores)
app.use(router)
app.use(i18n)
app.use(createVuestic({ config: vuesticGlobalConfig }))

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')
