<template>
  <VaSidebar width="" class="max-w-44 pt-2 bg-light-surface-container">
    <template v-for="{ name, meta } in crmNavigationRoutes.routes" :key="name">
      <RouterLink
        v-if="meta.requiredClaims?.length === 0 || (meta.requiredClaims && hasPermission(meta.requiredClaims[0]))"
        :to="{ name: name }"
        :title="meta.title"
        class="text-gray-600 active:bg-light-primary-container p-4 py-5 hover:bg-light-surface-container-high hover:text-primary transition-colors relative flex flex-col items-center active:scale-95 mr-2.5 rounded-r-full group"
        :class="
          route.name === name || route.fullPath.includes(name) ? 'bg-light-surface-container-high' : ' bg-transparent'
        "
      >
        <VaIcon
          :name="meta.icon"
          class="mx-4 group-hover:translate-x-2 group-active:translate-x-0 transition-transform"
          size="large"
        />
        <!-- <small v-if="meta.title && $route.name === name" class="overflow-hidden max-w-[75%] mx-auto w-full text-center"
          >{{ meta.title.substring(0, 7) }}{{ meta.title.length > 7 ? '.' : '' }}</small
        > -->
      </RouterLink>
    </template>
    <SoftPhone />
  </VaSidebar>
</template>
<script setup lang="ts">
import crmNavigationRoutes from '@/pages/crm/common/crmNavigationRoutes'
import SoftPhone from '../SoftPhone/Phone.vue'
// import { Claims } from '../../utils/enumsHandler'
import { usePermissions } from '../../utils/usePermisions'
import { useRoute } from 'vue-router'
const route = useRoute()

const { hasPermission } = usePermissions()
</script>
